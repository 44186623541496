.letscreate {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(20px, 4vw, 25px);
    font-weight: 700;
    line-height: 60px;
    text-align: left;

}

@media screen and (max-width: 500px) {
    .mobile {
        flex-direction: column;
    }
}