.black-modal .modal-content {
    background-color: #000000;
    border-radius: 28px;
    top: 0;
}

.black-modal .modal-content .modal-header {
    border: none;
}

.black-modal .modal-content .modal-footer {
    border: none;
    border-radius: 20px;
}

/* common */

.modal-title {
    width: 100%;
    text-align: center;
}

/* advertisement */

.black-modal-advertisement .modal-content {
    background-color: #000000;
    border-radius: 20px;
    top: 0;
}

.black-modal-advertisement .modal-content .modal-header {
    border-color: aliceblue;
}

/* save to */
.save-to .modal-content .modal-header {
    padding-bottom: 2% !important;
    border: none !important;
}

.save-to .modal-content .modal-header .modal-title {
    text-align: left !important;
    font-size: clamp(16px, 2vw, 20px) !important;
}

.save-to .modal-content {
    width: 60%;
    margin-left: 120px;
}

.save-to .modal-content .modal-footer {
    justify-content: flex-start !important;
}

/* center footer */
.customModalFooterCenter .modal-content .modal-footer {
    justify-content: center !important;
}

/* upload photo */
/* photo */

.black-modal-photo .modal-content {
    background-color: #000000;
    border-radius: 20px;
    top: 0;
}

.black-modal-photo .modal-content .modal-header {
    border-color: transparent;
}

.black-modal-photo .modal-content .modal-header .modal-title {
    font-family: var(--fontFamilyPoppins);
    font-weight: 800;
}