.banneras {
    height: 75vh;
    /* background-image: url('../../assets/images/svg/Logo_White_Gradient_Background.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem;
    position: relative;
}

.cardImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.logos {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.mainsec {
    display: flex;
}

.headingdistence {
    margin-top: 9.5rem;
}

.LoginSingupBtn {
    z-index: 999;
    position: absolute;
    right: 0;
    padding: 0 2rem;
}

.playstore {
    margin-right: 10px;
}

.dotsDiv img {
    width: 100%;
}

.musicDiv {
    position: absolute;
    top: 9.5rem;
}

.dots2 {
    display: none;
}

.or {
    margin: 1rem;
    margin-left: unset;
}

.music {
    position: absolute;
    top: 7rem;
    right: 7rem;
}

.altlogo {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 40%;
    z-index: 1;
}

.radialYellow {
    border-radius: 50%;
    box-shadow: 0 0 65px 52px #954900, 0 0 61px 34px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(134, 83, 0.0);
    right: 29rem;
    top: 8rem;
    position: absolute;
}

.radialBlue {
    border-radius: 50%;
    box-shadow: 0 0 65px 52px #004b84, 0 0 61px 34px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
    top: 16rem;
    position: absolute;
    left: 28rem;
}

.radialRed {
    border-radius: 50%;
    box-shadow: 0 0 50px 17px #da1414, 0 0 44px 40px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
    top: 15rem;
    position: absolute;
    right: 10%;
}

.mobileStore {
    z-index: 999;
    position: absolute;
    right: 0;
    padding: 0 2rem;
    top: 20rem;
}

.heading {
    font-family: 'Poppins', sans-serif;
    font-size: 25px !important;
}

.BtnGray {
    border-radius: 10px;
    padding: 5px;
    font-weight: unset !important;
    background: #5e5e5e;
    color: #fff;
    border: none;
    text-transform: none !important;
    text-align: left;
    max-width: 20rem;
    z-index: 999;
}

@media screen and (max-width: 1357px) {
    .altlogo {
        left: 50%;
        width: 35%;
    }
}

@media screen and (max-width: 1157px) {
    .altlogo {
        left: 55%;
        width: 35%;
    }
}

@media screen and (max-width: 957px) {
    .altlogo {
        left: 50%;
        width: 40%;
        top: 22%;
    }

    .music {
        right: 3rem;
    }

    .musicDiv {
        position: absolute;
        top: 15rem;
    }

    .mobileStore {
        right: 0;
        top: 25rem;
    }

    .radialYellow {
        right: 21rem;
        top: 6rem;
    }

    .radialBlue {
        top: 23rem;
        left: 20rem;
    }


    .radialRed {
        top: 18rem;
        left: 38rem;
    }
}



@media screen and (max-width: 768px) {
    .banneras {
        height: 100vh;
    }
    .music {
        /* top: 180px; */
        /* width: 85%; */
        float: right;
        width: 23%;
        top: 13rem;
        position: absolute;
    }

    .dots {
        width: 85%;
    }

    .dots2 {
        position: absolute;
        top: 277px;
        width: 75%;
        float: right;
        margin-left: 7rem;
        display: block;
    }

    .radialYellow {
        border-radius: 50%;
        box-shadow: 0 0 40px 29px #954900, 0 0 44px 27px rgba(255, 0, 254, 0.322), 0 0 143px 23px rgba(168, 94, 0);
        right: 21rem;
        top: 6rem;
        position: absolute;
    }

    .radialBlue {
        border-radius: 50%;
        box-shadow: 0 0 40px 29px #004b84, 0 0 44px 27px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
        top: 23rem;
        position: absolute;
        left: 20rem;
    }


    .radialRed {
        border-radius: 50%;
        box-shadow: 0 0 40px 17px #da1414, 0 0 44px 32px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
        top: 18rem;
        position: absolute;
        left: 38rem;
    }

    .altlogo {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        height: 40%;
        z-index: 1;
    }

    .musicDiv {
        position: absolute;
        top: 20rem;
    }

    .dotsDiv {
        position: relative;
    }

    .dotsDiv2 {
        position: relative;
    }

    .mobileStore {
        z-index: 999;
        position: absolute;
        right: 0;
        padding: 0 0rem;
        top: 18rem;
    }

    .storeImage {
        width: 2.5rem;
        height: 2.5rem;
    }

    .heading {
        font-family: 'Poppins', sans-serif;
        font-size: 25px !important;
    }

    .BtnGray {
        border-radius: 10px;
        padding: 5px;
        font-weight: unset !important;
        background: #5e5e5e;
        color: #fff;
        border: none;
        text-transform: none !important;
        text-align: center;
        z-index: 999;
    }


}


@media screen and (max-width: 700px) {
    .mobileStore {
        top: 20rem;
    }
}

@media screen and (max-width: 640px) {
    .mobileStore {
        top: 21rem;
    }

    .music {
        top: 10rem;
    }

    .radialYellow {
        border-radius: 50%;
        box-shadow: 0 0 40px 29px #954900, 0 0 44px 27px rgba(255, 0, 254, 0.322), 0 0 143px 23px rgba(168, 94, 0);
        left: 18rem;
        top: 6rem;
        position: absolute;
    }

    .radialBlue {
        border-radius: 50%;
        box-shadow: 0 0 40px 29px #004b84, 0 0 44px 27px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
        top: 23rem;
        position: absolute;
        left: 18rem;
    }


    .radialRed {
        border-radius: 50%;
        box-shadow: 0 0 40px 17px #da1414, 0 0 44px 32px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
        top: 16rem;
        position: absolute;
        left: 30rem;
    }
}

@media screen and (max-width: 510px) {


    .banneras {
        height: 80vh;
    }

    .musicDiv {
        position: absolute;
        top: 8rem;
    }

    .altlogo {
        top: 10%;
        left: 25%;
        width: 35%;
    }

    .mainsec {
        display: block;
    }

    .banneras {
        background-size: cover;
    }

    .headingdistence {
        margin-top: 3rem;
    }

    .mobileStore {
        top: 15rem;
    }

    .music {
        display: none;
    }


    .radialYellow {
        border-radius: 50%;
        box-shadow: 0 0 40px 29px #954900, 0 0 44px 27px rgba(255, 0, 254, 0.322), 0 0 143px 23px rgba(168, 94, 0);
        left: 15rem;
        top: 6rem;
        position: absolute;
    }

    .radialBlue {
        border-radius: 50%;
        box-shadow: 0 0 40px 29px #004b84, 0 0 44px 27px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
        top: 23rem;
        position: absolute;
        left: 15 rem;
    }


    .radialRed {
        border-radius: 50%;
        box-shadow: 0 0 40px 17px #da1414, 0 0 44px 32px rgba(255, 0, 255, 0.322), 0 0 175px 50px rgba(0, 83, 0.0);
        top: 16rem;
        position: absolute;
        left: 30rem;
    }
}

@media screen and (max-width: 450px) {

    .mobileStore {
        top: 12rem;
    }

    .radialYellow {
        left: 12rem;
        top: 6rem;
    }

    .radialBlue {
        top: 18rem;
        left: 12rem;
    }


    .radialRed {
        top: 16rem;
        position: absolute;
        left: 20rem;
    }

    .banneras {
        height: 75vh;
    }
}