.arrowRight {
    color: grey;
}

.arrowRight:hover {
    color: white;
}

.seeMore {
    color: grey;
}

.seeMore:hover {
    color: white;
}