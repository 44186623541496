.title {
    font-size: clamp(25px, 3vw, 35px);
    font-weight: 700;
    line-height: 72px;
    text-align: left;
    color: #43B5D8;
}

.title2 {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(12px, 1vw, 18px);
    font-weight: 400;
    line-height: 36px;
    text-align: left;
}