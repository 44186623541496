.signinas {
    /* display: flex; */
    /* align-items: center; */
    height: 100vh;
    /* justify-content: space-around; */
    background-image: url('../../assets/images/svg/signInAs-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem;
}

.heading {
    font-family: 'Poppins-bold', sans-serif;
    font-size: 35px;
    font-weight: 510;
    margin-left: 1px !important;
}

.cardImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.logo {
    /* position: absolute; */
    top: 0;
    left: 0;
    /* padding: 2rem; */
    cursor: pointer;
}