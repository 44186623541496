/* bottom section */
.bottomSection {
	background-color: #171717;
	background-repeat: no-repeat;
	background-image: url('../../assets/images/svg/WHITEBG.svg');
}

.bottomSectionHeading {
	font-family: 'Poppins-bold', sans-serif;
	font-size: 17.1px;
	margin-bottom: 2px;
	margin-left: 5px;
	line-height: 0;
	color: #fff;
}

.bottomSectionHeading1 {
	font-family: 'Poppins-bold', sans-serif;
	font-size: 3.5rem;
	color: #fff;
}

.bottomSectionParagraph {
	font-family: 'Poppins-regular', sans-serif;
	font-size: 14.9px;
	line-height: 1.5;
	font-weight: 500;
	padding-left: 26px;
	color: #fff;
	z-index: 1;
	padding-top: 0.8rem;
}

.bottom {
	margin-top: -3.1rem;
	display: flex;
	padding-left: 4vw;
	flex-direction: column;
	justify-content: start;
}

.appsLogo1 {
	flex-direction: column;
	margin-top: 14.6rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: right;
	margin-right: 0.4vh;
}

.phoneImage {
	margin-top: 11px;
	max-width: 140.8%;
	margin-left: 7px;
	width: 100%;
}

.appStore {
	height: 11.7vh;
	margin-bottom: 1rem;
}

.playStore {
	height: 11.8vh;
	margin-bottom: 0.6rem;
}

/* @media only screen and (max-width: 1025px) {
	.bottomSectionHeading {
		font-family: 'Poppins-bold';
		font-size: 17.1px;
		font-weight: 500;
		margin-bottom: 2px;
		margin-left: 5px;
		line-height: 1 !important;
		color: #fff;
	}
} */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
	.phoneImage {
		margin-top: 11px;
		max-width: 151.7%;
		margin-left: 9px;
	}

	.bottom {
		margin-top: 0rem !important;
		flex-direction: column;
		justify-content: center;
	}

	.bottomSectionHeading1 {
		font-family: 'Poppins-bold', sans-serif;
		font-size: 19.7px !important;
		color: #fff;
	}

	.appsLogo1 {
		flex-direction: column;
		margin-top: 5rem;
		display: flex !important;
		max-width: 1000px;
		flex-wrap: wrap !important;
		justify-content: right;
		margin-right: 0vh !important;
	}

	.appStore {
		height: 8.7vh !important;
		margin-bottom: 1rem;
		margin-right: 20px;
	}

	.playStore {
		height: 8.7vh !important;
		margin-bottom: 0.6rem;
	}

	.bottomSectionHeading {
		font-family: 'Poppins-bold', sans-serif;
		font-size: 17.1px;
		margin-bottom: 2px;
		margin-left: 5px;
		line-height: none !important;
		color: #fff;
	}
}

@media only screen and (max-width: 767px) {
	.phoneImage {
		margin-top: 11px;
		max-width: 100.7%;
		margin-left: 9px;
	}

	.bottom {
		margin-top: 1rem;
		display: flex;
		padding-left: 0.8vw !important;
		flex-direction: column;
		justify-content: center;
	}

	.bottomSectionHeading {
		font-family: 'Poppins-bold', sans-serif;
		font-size: 10px !important;
		margin-bottom: 2px;
		color: #fff;
	}

	.bottomSectionHeading1 {
		font-family: 'Poppins-bold', sans-serif;
		font-size: 17.7px !important;
		margin-left: 5px;
		color: #fff;
	}

	.appsLogo1 {
		flex-direction: column;
		margin-top: 0rem;
		display: flex !important;
		max-width: 1000px;
		flex-wrap: nowrap;
		justify-content: right;
		margin-right: 0vh !important;
	}

	@media only screen and (max-width: 530px) {
		.appsLogo1 {
			flex-direction: column;
			justify-content: center;
		}
	}

	.appStore {
		height: 6.7vh !important;
		margin-bottom: 1rem;
		margin-right: 15px !important;
	}

	@media only screen and (max-width: 530px) {
		.appStore {
			max-width: 120px;
		}
	}

	@media only screen and (max-width: 530px) {
		.playStore {
			max-width: 120px;
		}
	}

	.playStore {
		height: 6.7vh !important;
		margin-bottom: 0.6rem;
	}

	.bottomSectionParagraph {
		font-family: 'Poppins-regular', sans-serif;
		font-size: 7.9px;
		line-height: 1.5;
		font-weight: 500;
		padding-left: 6px;
		color: #fff;
		width: 55vh;
		z-index: 1;
		padding-top: 0.8rem;
	}
}

/* Footer section .... */

.footerSection {
	background-color: #01010a;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: row;
	padding: 0 4vw;
	/* padding-left: 15vh; */
	padding-bottom: 15px;
	padding-top: 16px;
}

.logoContainer {
	margin-top: 5rem;
}

.footerHeading {
	font-family: 'Poppins', sans-serif;
	font-size: 40px;
	font-weight: 600;
	color: #fff;
	margin-top: 5rem;
}

.footerParagraph {
	font-family: 'Poppins', sans-serif;
	font-size: 15px;
	font-weight: 200;
	color: #fff;
	margin-top: 1rem;
}

.footerHeading4 {
	font-family: 'Poppins-Regular', sans-serif;
	font-size: 16.9px;
	font-weight: 700;
	color: #a1a1a1;
	letter-spacing: 0.3px;
	margin-top: 1.4rem;
	/* margin-left: 1px; */
}

.aboutHeading {
	font-family: 'Poppins-Regular', sans-serif;
	color: #6975d4;
	font-size: 17.2px;
	font-weight: 800;
	margin-top: 2.8rem;
	padding-left: 10px;
	letter-spacing: 0.3px;
	margin-bottom: 2.1rem;
}

.logoSection {
	padding: 0 2vh 0 11vh;
}

.footerParagraph2 {
	padding-top: 19px;
	padding-bottom: 3px;
	font-family: 'Poppins-Regular', sans-serif;
	font-size: 11.2px;
	color: #fff;
	line-height: 1.5;
}

.aboutSection {
	padding: 9.5vh 13.1vh;
	margin-left: px;
}

.forSection {
	padding: 9.5vh 10vh;
	margin-left: px;
}

.For2Section {
	padding: 9.5vh 7.7vh;
	margin-left: px;
}

.aboutParagraph {
	color: white;
	font-family: 'Poppins-Regular', sans-serif;
	font-size: 9.7px;
	font-weight: 200;
	padding-left: 10px;
	margin-top: 13.3px !important;
}

.footerBottomHeading {
	color: #b5b5b5;
	font-family: 'Poppins-Regular', sans-serif;
	font-size: 9px;
	text-align: center;
	font-weight: 500;
	margin-top: rem;
	letter-spacing: 0px;
	margin-bottom: -2px !important;
}

.footerSection .socialIcons {
	display: flex;
	flex-direction: row;
	margin-top: 6px;
	justify-content: left;
}

.footerSection .socialIcons img {
	background-color: white;
	height: 3.9vh;
	width: 3.9vh;
	margin: 0px 0rem;
	border-radius: 4px;
}

.footerSection .logoContainer {
	width: 11vw;
	min-width: 100px;
	height: 7vh;
}

.footerSection .logoContainer img {
	width: 100%;
}

.footerSection2 {
	display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
	.logoSection {
		padding: 0 1.5vh !important;
		margin-top: -15px !important;
		display: flex;
		justify-content: c;
	}

	.aboutSection {
		padding: 9.5vh 1.8vh;
		margin-left: px;
	}

	.forSection {
		padding: 9.5vh 1vh;
		margin-left: px;
	}

	.For2Section {
		padding: 9.5vh 2vh;
		margin-left: px;
	}
}

@media only screen and (max-width: 767px) {
	.logoSection {
		padding: 5px;
		margin-top: -15px !important;
		display: flex;
		justify-content: center;
	}

	.aboutSection {
		padding: 5px;
		text-align: center;
	}

	.forSection {
		padding: 5px;
		text-align: center;
	}

	.For2Section {
		padding: 5px;
		text-align: center;
	}

	.footerSection2 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-left: -20px;
	}

	.aboutSection2 {
		padding: 9.5vh 1vh 3vh;
		margin-left: px;
	}

	.forSection2 {
		padding: 9.5vh 1vh 3vh;
		margin-left: px;
	}

	.For2Section2 {
		padding: 9.5vh 1vh 3vh;
		margin-left: px;
	}

	.aboutHeading {
		font-family: 'Poppins-Regular', sans-serif;
		color: #6975d4;
		font-size: 9.2px;
		font-weight: 800;
		margin-top: 2.8rem;
		padding-left: 10px;
		letter-spacing: 0.3px;
		margin-bottom: 2.1rem;
	}

	.aboutParagraph {
		color: white;
		font-family: 'Poppins-Regular', sans-serif;
		font-size: 6.7px;
		font-weight: 200;
		padding-left: 10px;
		margin-top: 13.3px !important;
	}

	.footerParagraph2 {
		padding-top: 19px;
		padding-bottom: 3px;
		font-family: 'Poppins-Regular', sans-serif;
		font-size: 9.2px;
		color: #fff;
		line-height: 1.5;
	}

	.footerHeading4 {
		font-family: 'Poppins-Regular', sans-serif;
		font-size: 13.9px;
		font-weight: 700;
		color: #a1a1a1;
		letter-spacing: 0.3px;
		margin-top: 1.4rem;
	}

	.footerBottomHeading {
		color: #b5b5b5;
		font-family: 'Poppins-Regular', sans-serif;
		font-size: 9px;
		text-align: center;
		font-weight: 500;
		margin-top: rem;
		letter-spacing: 0px;
		margin-bottom: 20px !important;
	}
}

@media only screen and (max-width: 576px) {

	.logoSection {
		width: 25% !important;
	}

	.aboutSection {
		padding: 5px;
		text-align: center;
		width: 25% !important;

	}

	.forSection {
		padding: 5px;
		text-align: center;
		width: 25% !important;

	}

	.For2Section {
		padding: 5px;
		text-align: center;
		width: 25% !important;

	}
}


@media only screen and (max-width: 500px) {
	.logoSection {
		padding: 5px;
		margin-top: -15px !important;
		display: flex;
		justify-content: center;
		width: 100% !important;
	}

	.aboutSection {
		padding: 5px;
		text-align: center;
		width: 33.3% !important;

	}

	.forSection {
		padding: 5px;
		text-align: center;
		width: 33.3% !important;

	}

	.For2Section {
		padding: 5px;
		text-align: center;
		width: 33.3% !important;

	}

	.footerSection .socialIcons {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

}
