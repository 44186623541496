.title {
    font-family: var(--fontFamilyPoppins);
    font-size: clamp(22px, 2vw, 28px);
    font-weight: 700;
    line-height: 46.5px;
    text-align: left;
    color: #FFFFFF;
    text-transform: uppercase;
}

.advertisementDiv1 {
    display: flex;
    flex-direction: column;
    margin: 0 2% 0 4%;
}

.searchBarDiv {
    flex: 0 0 auto;
    width: 85%;
}

.searchInput {
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 20px 20px;
    color: #ABABAB;
    background-color: #000000;
    border: 3px solid #262626;
    border-radius: 10px;
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    max-height: 63px;
}
.advertisementCards {
    display: flex;
    flex-wrap: wrap;
    margin: 1%;
    justify-content: center;

}