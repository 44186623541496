@font-face {
	font-family: 'Poppins-Bold';
	src: url('/src/assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-Regular';
	src: url('/src/assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-Semibold';
	src: url('/src/assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-Medium';
	src: url('/src/assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins-Light';
	src: url('/src/assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Segoe-Regular';
	src: url('/src/assets/fonts/Segoe\ UI.ttf') format('truetype');
}

@font-face {
	font-family: 'Segoe-Semibold';
	src: url('/src/assets/fonts/Segoe\ UI\ Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Segoe-Semibold';
	src: url('/src/assets/fonts/ARIAL.TTF') format('truetype');
}

body {
	margin: 0;
	font-family: var(--fontFamily) !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--fontColorWhite) !important;
	box-sizing: border-box;
	font-size: var(--fontSize) !important;
	overflow-x: hidden !important;
	background-color: #000;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	--background1Color: #30303a;
	--background2Color: #24252f;
	--button1BackgroundColor: #f33d40;
	--fontColor: #c0bfc2;
	--fontColor2: #f57308;
	--fontColor3: #f33d40;
	--blueColor: #48bdde;

	--fontColorOrange: #f74037;
	--fontColorWhite: #ffffff;
	--fontColorBlack: black;
	--backgroundColorOrange: #f74037;
	--backgroundColorWhite: #ffffff;
	--fontFamily: 'Poppins-Regular';
	--fontFamilyBold: 'Poppins-Bold';
	--fontFamilySemiBold: 'Poppins-Semibold';
	--fontFamilyMedium: 'Poppins-Medium';
	--fontFamilyLight: 'Poppins-Light';
	--fontFamilySegoe: 'Segoe-Regular';
	--fontFamilySegoeSemi: 'Segoe-Semibold';
	--fontFamilyArial: 'Arial';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px black inset !important;
	/* Change 30px to the desired value */
	-webkit-text-fill-color: white !important;
	caret-color: white !important;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track:vertical {
	background: #262626;
	border-radius: 5rem;
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
	background: #EE3D42;
	border-radius: 5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover:vertical {
	background: #555;
	border-radius: 5rem;
}

/* Input type Date */
input#dobDate {
	display: inline-block;
	position: relative;
	text-transform: uppercase;
	color-scheme: light dark;
}

input[type="date"]::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
	text-transform: capitalize;
}

/* input textarea placeholder */
textarea::placeholder {
	/* Modern browsers */
	color: #fff;
}

/* bootstrap dropdwon */
.dropdown-menu {
	background-color: #1F2125;
	--bs-dropdown-link-hover-bg: #424242;
	--bs-dropdown-link-hover-color: var(--fontColorWhite);
	border: 1px solid #424242;
	inset: 5px -95px auto auto !important;
	/* -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s; */
	transform: translate3d(-100px, 52px, 0px) !important;
}

.dropdown-item {
	color: var(--fontColorWhite);
	cursor: pointer;
}


/* input box number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}