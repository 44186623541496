.sidebar__container {
    display: flex;
    height: 150px;
    position: relative;
}

.sidebar {
    flex: 0;
    height: 100%;
    background: lightblue;
    overflow: hidden;
    transition: width 0.6s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.sidebar.opened {
    flex-basis: 250px;
}

.sidebar__content {
    background-color: lightcoral;
    flex: 1;
    transition: flex 0.6s;
    z-index: 0;
}

@media screen and (max-width: 425px) {
    .sidebar__container {
        display: block;
        position: fixed;
    }

    .sidebar.opened {
        width: 100%;
        /* Make the sidebar occupy full width */
        z-index: 2;
        /* Ensure sidebar is on top */
    }
}

/* For small screens (sm) */
.position-fixed-sm {
    position: fixed;
}

/* For medium (md) and large (lg) screens */
.position-sticky-md-lg {
    position: sticky;
}

.navLinks {
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
}

.navLinksClose {
    padding: 0.8rem 1rem;
    font-size: 0.85em;
    justify-content: center;
}

.sideBarClosedDiv{
    width: 4%;
}