.authCard {
    height: 100%;
    max-height: 679px;
    /* width: 23.6vw; */
    min-width: 310px;
    background-color: #000;
    margin-right: 11px;
    border-radius: 26px;
    padding: 3.9vh 3.2vh;
}

.authcardheading {
    font-family: var(--fontFamilyMedium);
    color: var(--fontColorWhite);
    font-size: 17.1px;
    padding-left: 13px;
    padding-bottom: 1.3rem;
    border-bottom: 1px solid #525151;
    margin-bottom: 2.5rem;
}

.heading {
    font-family: var(--fontFamilyLight);
    color: var(--fontColorWhite);
    margin-bottom: 19px;
    padding-left: 13px;
    font-size: 17.3px;
}

.cardImg {
    width: 11.9vw;
    min-width: 145px;
    height: 24.5vh;
    display: flex;
    margin: auto;
    padding-left: 12px;
    justify-content: center;
    margin-bottom: 2.2vh;
}

.cardHeading{
    font-family: var(--fontFamilyBold);
    color: #F74037;
    font-size: 35px;
    text-transform: uppercase;
}