.searchHistoryListDiv {
    position: absolute;
    border-radius: 15px;
    background: #262626;
    width: 33%;
    margin-top: 0.5%;
    padding: 1%;
}

.innerDiv {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    gap: 5%;
}

.innerDiv p {
    padding: 0;
    margin: 0;
    color: #ccc;
    text-transform: capitalize;
}

.innerDiv:hover {
    background-color: #313131;
}