/* big play button */
.vjs-altRadar .vjs-big-play-button {
    border: none;
    background-color: transparent;
    /* content: url('/src/assets/images/icons/videoPlayer/Play.svg') !important; */
    font-family: 'Font Awesome 6 Free';
    /* Ensure this matches the Font Awesome version */
    font-weight: 900;
    /* Font Awesome Free uses 900 for solid icons */
    content: '\f04b';
    /* Unicode for the specific icon */
    font-size: 24px;
    color: #ffffff;
    /* Example color */
    height: 2em;
    width: 2em;

}

.vjs-altRadar button {
    top: 1rem;
}

/* VideoPlayer */
.vjs-altRadar .vjs-slider {
    background: rgba(52, 52, 52, 1) !important;
    margin: 0 !important;
}

.vjs-altRadar .vjs-volume-level,
.vjs-altRadar .vjs-play-progress,
.vjs-altRadar .vjs-slider-bar {
    background: rgba(255, 59, 59, 1);

}

.vjs-icon-play:before,
.vjs-altRadar .vjs-play-control .vjs-icon-placeholder:before,
.vjs-altRadar .vjs-big-play-button .vjs-icon-placeholder:before {
    /* content: url('/src/assets/images/icons/videoPlayer/videoPlayIcon.svg') !important; */

    font-family: 'Font Awesome 6 Free';
    /* Ensure this matches the Font Awesome version */
    font-weight: 900;
    /* Font Awesome Free uses 900 for solid icons */
    content: '\f04b';
    /* Unicode for the specific icon */
    font-size: 1.8em;
    color: #ffffff;
    /* Example color */
    line-height: 1;
}

.vjs-icon-pause:before,
.vjs-altRadar .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    /* content: url('/src/assets/images/icons/videoPlayer/videoPauseIcon.svg') !important; */
    font-family: 'Font Awesome 6 Free';
    /* Ensure this matches the Font Awesome version */
    font-weight: 900;
    /* Font Awesome Free uses 900 for solid icons */
    content: '\f04c';
    /* Unicode for the specific icon */
    font-size: 1.8em;
    color: #ffffff;
    /* Example color */
    line-height: 1;
}

.vjs-icon-volume-mute:before,
.vjs-altRadar .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
    /* content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.30587 0.459073L14.2999 5.60707L23.2929 10.7551C23.8314 11.0592 24.2797 11.5006 24.5921 12.0343C24.9046 12.568 25.0701 13.175 25.0717 13.7934C25.0733 14.4119 24.911 15.0197 24.6014 15.555C24.2917 16.0904 23.8458 16.5341 23.3089 16.8411L14.3539 22.0561L5.39787 27.2711C4.86526 27.5823 4.25984 27.7473 3.64294 27.7493C3.02604 27.7512 2.41959 27.5901 1.88502 27.2822C1.35044 26.9743 0.906748 26.5306 0.598875 25.996C0.291002 25.4614 0.129893 24.855 0.13187 24.2381L0.0928687 13.8751L0.0548686 3.51207C0.0486057 2.89896 0.204911 2.29514 0.50787 1.76207C0.817702 1.22724 1.26242 0.783057 1.79763 0.473869C2.33283 0.16468 2.93978 0.00131025 3.55787 7.34762e-05C4.17084 -0.00391387 4.77394 0.154451 5.30587 0.459073Z' fill='white'/%3E%3C/svg%3E%0A") !important; */
    width: 20px !important;
    height: 20px !important;
    font-family: 'Font Awesome 6 Free';
    /* Ensure this matches the Font Awesome version */
    font-weight: 900;
    /* Font Awesome Free uses 900 for solid icons */
    content: '\f6a9';
    /* Unicode for the specific icon */
    font-size: 1.8em;
    color: #ffffff;
    /* Example color */
    line-height: 1;
}

.vjs-icon-volume-high:before,
.vjs-altRadar .vjs-mute-control .vjs-icon-placeholder:before {
    /* content: url('/src/assets/images/icons/videoPlayer/mute.svg') !important; */
    font-family: 'Font Awesome 6 Free';
    /* Ensure this matches the Font Awesome version */
    font-weight: 900;
    /* Font Awesome Free uses 900 for solid icons */
    content: '\f028';
    /* Unicode for the specific icon */
    font-size: 1.8em;
    color: #ffffff;
    /* Example color */
    line-height: 1;
}

/* Next Button */
.vjs-altRadar .vjs-control-bar .vjs-next-button {
    background-image: url('/src/assets/images/icons/videoPlayer/next.svg') !important;
    background-repeat: no-repeat;
    background-size: 1.6em;
    background-position: center top;
    /* line-height: 1.67; */
    /* Set the line height */
    display: inline-block;
    /* Ensure the element is treated as a block for proper height handling */
    vertical-align: middle;
    /* Align the element vertically in line with text */
    line-height: none;
}

.vjs-altRadar .vjs-control-bar .vjs-share-button {
    background-image: url('/src/assets/images/icons/videoPlayer/next.svg') !important;
    background-repeat: no-repeat;
    background-size: 1.6em;
    background-position: center top;
    /* line-height: 1.67; */
    /* Set the line height */
    display: inline-block;
    /* Ensure the element is treated as a block for proper height handling */
    vertical-align: middle;
    /* Align the element vertically in line with text */
    line-height: none;
}

.video-js .vjs-time-control {
    /* display: block; */
    font-family: Arial;
    font-size: 1.3em !important;
    font-weight: 400;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    top: 1rem;
    height: fit-content;
}

.video-js .vjs-current-time {
    padding-right: 0;
}

.video-js .vjs-duration {
    padding-left: 0;

}

.video-js .vjs-time-control span {
    color: rgba(255, 255, 255, 1);
    line-height: 1.4;
}

.vjs-altRadar span {
    color: rgba(255, 255, 255, 1);
}

.vjs-altRadar .vjs-time-divider {
    padding: 0;
    min-width: none;
    top: 1rem;
    position: relative;
    line-height: 1.4;
}

/* Volume Bar */
.video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal {
    top: 60%;
    position: relative;
    line-height: 0;
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
    /* top: 1rem; */
    top: 40%;
    line-height: 0;
}

.video-js .vjs-remaining-time {
    /* display: none; */
    font-family: Arial;
    /* font-size: 15px; */
    font-weight: 400;
    display: flex;
    align-items: center;
}

.vjs-altRadar .vjs-load-progress div {
    background: rgba(134, 133, 134, 1);
    border-radius: 1rem;
}

.video-js .vjs-load-progress {
    background: none
}

.vjs-altRadar .vjs-fullscreen-control {
    position: absolute;
    right: 0%;
    top: 0em;
}

.vjs-altRadar .vjs-picture-in-picture-control {
    position: absolute;
    right: 4%;
    top: 0em;
}

.vjs-altRadar .vjs-control-bar {
    background: rgba(19, 19, 19, 1);
    height: 4em;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    /* padding-top: 1%; */
}

/* Ensure the top progress control is positioned correctly */
.video-js .vjs-control-bar .vjs-progress-control {
    position: absolute;
    width: 100%;
    z-index: 1;
    align-items: baseline;
    height: fit-content;
}

.video-js .vjs-control-bar {
    /* position: relative; */
    z-index: 2;
    display: flex;
    background: transparent;
}

.vjs-button>.vjs-icon-placeholder:before {
    font-size: 2.8em;
    line-height: 1.67;
}

/* Custom styles for the share button */
/* .vjs-share-button {
    border: none;
    padding: 2%;
    cursor: pointer;
    top: 14%;
    position: absolute;
    right: 38%;
    background-image: url('/src/assets/images/icons/videoPlayer/shareIcon.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 2em !important;
    background-position: center center !important;
    background: transparent;
} */
/* sonarqube fix class below */
.vjs-share-button {
    border: none;
    padding: 2%;
    cursor: pointer;
    top: 14%;
    position: absolute;
    right: 38%;
    background: url('/src/assets/images/icons/videoPlayer/shareIcon.svg') no-repeat center center / 2em transparent !important;
}


/* Additional styles for full-screen mode */
/* Adjust as needed for full-screen mode */
/* Adjust as needed for full-screen mode */
/* .video-js.vjs-fullscreen .vjs-share-button {
    top: 20px;
    right: 20px;
} */

/* Additional styles for hover effect */
.vjs-share-button:hover {
    background-color: none;
}

/* Additional styles for active state */
.vjs-share-button:active {
    background-color: none;
}

/* Watch Later Custom Button */
.vjs-watchlater-button {
    border: none;
    padding: 2%;
    cursor: pointer;
    top: 14%;
    position: absolute;
    right: 41.5%;
    background-image: url('/src/assets/images/icons/videoPlayer/watchLater.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 1.5rem 1.5rem !important;
    background-position: center center !important;
}

/* Additional styles for hover effect */
.vjs-watchlater-button:hover {
    background-color: none;
}

/* Additional styles for active state */
.vjs-watchlater-button:active {
    background-color: none;
}

.vjs-title-bar {
    display: contents !important;
}

/* custom button full screen handling */
.video-js.vjs-fullscreen .vjs-share-button {
    left: 95%;
    top: -45%;
}

.video-js.vjs-fullscreen .vjs-watchlater-button {
    left: 92%;
    top: -45%;
}

.vjs-share-button-top-right,
.vjs-watchlater-button-top-right {
    position: absolute !important;
    top: 2% !important;
    right: 10px;
    height: 10% !important;
    /* z-index: 10; */
}

.vjs-watchlater-button-top-right {
    right: 50px;
    /* Adjust as necessary */
}

/* Fullscreen control bar styles */
.vjs-fullscreen .vjs-control-bar {
    position: absolute;
    border-radius: 0;
    /* background-color: rgba(0, 0, 0, 0.8); */
    /* Add any other fullscreen-specific styles here */
}

/* Apply this class only when in fullscreen */
.vjs-fullscreen.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    display: flex;
    visibility: visible;
    opacity: 0;
    transition: visibility 1s, opacity 1s;
}

/* Apply this class only when in fullscreen */
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    display: flex;
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
}

.video-js .vjs-tech {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.vjs-fullscreen.vjs-altRadar .vjs-picture-in-picture-control {
    position: absolute;
    right: 3%;
}

/* After video ended play button */
.vjs-icon-replay:before,
.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before {
    /* content: "\f11b"; */
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    content: '\f04b';
    font-size: 1.8em;
    color: #ffffff;
    line-height: 1;
}

/* youtube */
.ytp-chrome-top.ytp-show-cards-title {
    display: none !important;
}

/* Hide YouTube's native Watch Later, Share, and Info buttons */
.video-js .ytp-chrome-top-buttons {
    display: none !important;
}

.video-js .ytp-button {
    display: none !important;
}

/* Hide YouTube's Watch Later, Share, and Info buttons in the video player */
.video-js .ytp-chrome-top-buttons,
.video-js .ytp-share-button,
.video-js .ytp-watch-later-button,
.video-js .ytp-chrome-top,
.video-js .ytp-button {
    display: none !important;
}

/* Also ensure that the buttons remain hidden during mouse hover */
.video-js:hover .ytp-chrome-top-buttons,
.video-js:hover .ytp-share-button,
.video-js:hover .ytp-watch-later-button,
.video-js:hover .ytp-chrome-top,
.video-js:hover .ytp-button,
.video-js:hover .ytp-pause-overlay {
    display: none !important;
}

/* Attempt to hide the "Watch Later" button */
.video-js .vjs-tech iframe {
    pointer-events: none;
    position: relative;
    /* This may affect other interactions */
}

.video-js .vjs-tech .ytp-button.ytp-watch-later-button,
.video-js .vjs-tech .ytp-button.ytp-watch-later-button-renderer {
    display: none !important;
}

.overlay-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* Allows video interaction but blocks hover actions */
    z-index: 10;
}